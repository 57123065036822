<template>
  <content-with-sidebar
      v-if="Object.keys(post).length"
      class="cws-container cws-sidebar-right post-wrapper"
  >

    <b-overlay :show="dataLoadingPost" rounded="sm">
    <!-- Контент -->
    <div class="post-detail-wrapper">
      <b-row>

        <!-- Карточка  -->
        <b-col cols="12">
          <b-card
              :img-src="post.picture"
              img-top
              img-alt="post Detail Pic"
              :title="post.title"
          >

            <!-- Автор -->
            <b-media no-body>
              <b-media-aside
                  vertical-align="center"
                  class="mr-50"
              >
                <b-avatar
                    size="2rem"
                    :src="post.author.avatar"
                />
              </b-media-aside>
              <b-media-body>
                  <b-link class="text-body">{{ post.author.fullName }}</b-link>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">
                  <span>{{ post.created_at | moment("DD.MM.YYYY") }}</span>
                </small>
              </b-media-body>
            </b-media>

            <!-- Чипсы \\ категории -->
            <div class="my-1 py-25">
              <b-link
                  v-for="category in post.categories"
                  :key="category.id"
              >
                <b-badge
                    pill
                    class="mr-75"
                    variant="light-primary"
                >
                  {{ category.title }}
                </b-badge>
              </b-link>
            </div>

            <!-- Текст статьи  -->
            <div
                class="post-content"
                v-html="post.text"
            />

            <hr class="my-2">

            <!-- Значок комментария -->
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-1">
                  <b-link class="mr-50">
                    <feather-icon
                        icon="MessageSquareIcon"
                        size="21"
                        class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ comments.length }}
                    </div>
                  </b-link>
                </div>
              </div>

              <div class="d-flex align-items-center" v-if="$store.state.app.user && $store.state.app.user.account_type === 'ADMIN'">
                <div class="d-flex align-items-center mr-1">
                  <b-link class="mr-50" :to="{ name: 'post-edit', params: {id: id } }">
                    <feather-icon
                        icon="EditIcon"
                        size="21"
                        class="text-body"
                    />
                  </b-link>
                </div>
              </div>

            </div>
          </b-card>
        </b-col>

        <!-- Комментарии -->
        <b-col
            id="postComment"
            cols="12"
            class="mt-2"
        >

          <!-- Заголовок -->
          <h6 class="section-label">
            Комментарии
          </h6>

          <!-- Комментарий -->
          <b-overlay :show="dataLoadingComments" rounded="sm">
          <b-card
              v-for="(comment,index) in comments"
              :key="index"
          >
            <b-media no-body>

              <!--  Автор комментария  -->
              <b-media-aside class="mr-75">
                <b-avatar
                    :src="comment.user.avatar"
                    size="38"
                />
              </b-media-aside>
              <b-media-body>
                <h6 class="font-weight-bolder mb-25">
                  {{ comment.user.fullName }}
                </h6>
                <small>{{ comment.created_at | moment("DD.MM.YYYY") }}</small>
                <hr>
                <b-card-text>
                  {{ comment.text }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
          </b-overlay>
        </b-col>

        <!-- Форма для комментариев -->
        <b-col
            cols="12"
            class="mt-2"
        >
          <h6 class="section-label">
            Оставить комментарий
          </h6>
          <b-card>
            <validation-observer
                v-slot="{invalid}"
            >
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-group class="mb-2">
                      <validation-provider rules="required">
                        <b-form-textarea v-model="form.comment"></b-form-textarea>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        :disabled="invalid"
                        @click="submitComment"
                    >
                      Отправить
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </div>
    </b-overlay>

    <!-- СайдБар -->
    <div
        slot="sidebar"
        class="post-sidebar py-2 py-lg-0"
    >

      <!-- Опубликованные новости -->
      <div class="post-recent-posts mt-0">
        <h6 class="section-label mb-75">
          Свежие посты
        </h6>
        <b-media
            v-for="(recentpost,index) in sidebar.recentPosts"
            :key="recentpost.id"
            no-body
            :class="index? 'mt-2':''"
            @click="toRecentPost(recentpost)"
        >
          <b-media-aside class="mr-2">
            <b-link :to="{ name: 'post-page', params: { slug: recentpost.slug, id:recentpost.id } }">
              <b-img
                  class="recent-post-img"
                  :src="recentpost.picture"
                  width="100"
                  rounded
                  fluid
                  height="70"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="post-recent-post-title truncate">
              <b-link class="text-body-heading" :to="{ name: 'post-page', params: { slug: recentpost.slug, id:recentpost.id } }">
                {{ recentpost.title }}
              </b-link>
            </h6>
            <small class="text-muted">
              <span>{{ recentpost.created_at | moment("DD.MM.YYYY") }}</span>
            </small>
          </b-media-body>
        </b-media>
      </div>

    </div>
  </content-with-sidebar>
</template>

<script>
import {
  BFormInput,  BOverlay, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import ModalNewCategory from "@/components/views/news/modals/ModalNewCategory";
import BlInput from "@/components/controls/BlInput";
import BlTextArea from "@/components/controls/BlTextArea";
import Ripple from 'vue-ripple-directive'
import {kFormatter} from '@core/utils/filter'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import '@/libs/custom-validate'
import api from "@/api";
import utils from "@/utils";
import CategoriesList from "@/components/views/news/sidebar/CategoriesList";

export default {
  components: {
    BOverlay,
    BlInput,
    BlTextArea,
    BFormInput,
    ModalNewCategory,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    ContentWithSidebar,
    CategoriesList,

    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },

  mounted() {
    this.getCategories()
    this.getPost()
    this.getRecentPosts()

  },
  methods: {
    kFormatter,
    submitComment() {
      const self = this
      api.posts
          .addComment(this.id, this.form.comment)
          .then((response) => {
            const data = response.data;
            if (data && !data.error) {
              this.form.comment = ''
              utils.notificate(self, data.message);
              this.getComments(this.post.id)
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },

    getCategories() {
      const self = this;
      api.posts
          .getCategories()
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.sidebar.categories = data.result
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    getPost() {
      const self = this;
      api.posts
          .getPostBySlug(this.$router.currentRoute.params.slug)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.post = data.result[0]
              this.id = this.post.id
              this.post.author.fullName = `${this.post.author.last_name} ${this.post.author.first_name} ${this.post.author.middle_name}`
              this.categoryTitle = []
              this.getComments(this.post.id)
              for (let category in this.post.categories) {
                let id = (this.post.categories[category])
                for (let title in this.sidebar.categories) {
                  if (this.sidebar.categories[title].id === id) {
                    this.categoryTitle.push(this.sidebar.categories[title].title)
                  }
                }
              }
            this.dataLoadingPost = false
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    getComments(id) {
      const self = this;
      self.dataLoadingComments = true;
      api.posts
          .getComments(id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.comments = data.result
              for (let comment in this.comments) {
                let id = this.comments[comment].user
                api.users
                    .getUserById(id)
                    .then((response) => {
                      const temp = response.data;
                      if (data.error) {
                        throw "Ошибка";
                      } else {
                        this.comments[comment].user = temp.result
                        this.comments[comment].user.fullName = `${this.comments[comment].user.last_name} ${this.comments[comment].user.first_name} ${this.comments[comment].user.middle_name}`
                      }
                    })
              }
              self.dataLoadingComments = false;
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    getRecentPosts(){
      const self = this;
      api.posts
          .getRecentPosts()
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.sidebar.recentPosts = data.result
              for (let post of this.sidebar.recentPosts){
                if (!post.picture){
                  post.picture = "/static/images/no_photo.png"
                }
              }
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },

    toRecentPost(post){
      this.dataLoadingPost = true
      this.$router.push(`/posts/${post.slug}`)
      this.getPost(post.id)
      this.dataLoadingPost = false

    }

  },

  data() {
    return {
      isAddNewCategorySidebarActive: false,
      dataLoadingPost: true,
      dataLoadingComments: true,

      id: '',
      search_query: '',
      commentCheckmark: '',
      post: [],
      sidebar: {
        categories: [],
        recentPosts: [],
      },
      comments: [],
      form: {
        name: '',
        email: '',
        comment: '',
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.recent-post-img{
  object-fit: cover;
}
</style>


